:root {
  --off-white: #fafafa;
}
.main-nav {
  background-color: var(--off-white);
  position: sticky;
  top: 0px;
  width: 100%;
  height: 80px;
  z-index: 1000;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}

.main-nav-logo {
  height: 100px;
}

.main-nav-logo:hover {
  cursor: pointer;
}

.main-nav .main-nav-content {
  max-width: 900px;
  padding: 0rem 3rem;
  display: flex;
  justify-content: auto;
  align-items: center;
  height: 100%;
}

.main-nav-item {
  display: inline;
  margin-left: 2rem;
  margin-right: 2rem;
  color: #333;
}

.main-nav-item:hover {
  color: blueviolet;
  cursor: pointer;
}